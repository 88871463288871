import React, { useEffect } from "react";

import "aos/dist/aos.css";

import ReactMarkdown from "react-markdown";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/seo";
import { sendAmplitudeData } from "../utils/amplitude"
import { AmplitudeEvents } from "../types/amplitudeEvents"
import ResponsiveImage from "../components/responsiveImage";
import NotFoundPage from "./404";

const AboutUsPage = (context) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    sendAmplitudeData(AmplitudeEvents.ABOUT_US_VISITED);
    const AOS = require("aos");
    AOS.init({
      duration: 1800,
      once: true
    });

    if (AOS) {
      AOS.refresh();
    }
  }, []);

  const headerData = context.pageContext.headerConfigurations;
  const footerData = context.pageContext.footerConfigurations;

  const aboutUsPageConfigs = context.pageContext.node;

  if (!aboutUsPageConfigs) return <NotFoundPage />;

  return (
    aboutUsPageConfigs && <Layout headerData={headerData} footerData={footerData}>
      { aboutUsPageConfigs.seo && <SEO page={aboutUsPageConfigs.slug} seo={aboutUsPageConfigs.seo} /> }

      <section className="about-section">
        <div className="content-layer">
          <h1 className="h1" data-aos="animate-reveal">{aboutUsPageConfigs.HeroSection.SectionHeading}</h1>

          <div className="image-wrapper" data-aos="animate-reveal">
            <ResponsiveImage src={aboutUsPageConfigs.HeroSection.HeroImage.url} />
          </div>

          <p>{aboutUsPageConfigs.HeroSection.Description}</p>

          <div className="meet-our-team-section">
            <h3 className="h1">{aboutUsPageConfigs.Team.section_heading}</h3>

            <ReactMarkdown className="section-description">
              {aboutUsPageConfigs.Team.team_intro}
            </ReactMarkdown>

            <ul className="team-members">
              {
                aboutUsPageConfigs.Team.team_member.map((member, index) => {
                  return <li key={`${member.Name}_${index}`}>
                    <ResponsiveImage src={member.Picture.url} alt={member.Picture.name} />

                    <h5>{member.Name}</h5>
                    <h6>{member.Designation}</h6>
                    <p>{member.Bio}</p>
                  </li>
                })
              }
              { aboutUsPageConfigs.Team.team_member.length % 3 === 1 && <li className="empty-list-item" role="presentation"></li> }
              { aboutUsPageConfigs.Team.team_member.length % 3 === 2 && <li className="empty-list-item" role="presentation"></li> }
            </ul>

            <div className="photo-grid">
              <h3 className="h1">{aboutUsPageConfigs.Team.Gallary.gallary_heading}</h3>

              <ul className="gallery">
                {
                  aboutUsPageConfigs.Team.Gallary.images.map((image, index) => {
                    return <li key={`${image.name}_${index}`}>
                      <ResponsiveImage src={image.url} alt={image.name} />
                    </li>
                  })
                }
                { aboutUsPageConfigs.Team.Gallary.images.length % 3 === 1 && <li className="empty-list-item" role="presentation"></li> }
                { aboutUsPageConfigs.Team.Gallary.images.length % 3 === 2 && <li className="empty-list-item" role="presentation"></li> }
              </ul>
            </div>
          </div>
        </div>

        <div className="background-layer" role="presentation">
          <div data-aos="slide-left" className="stripes-on-pink-triangle"></div>
          <div data-aos="slide-left" className="pink-triangle"></div>
          <div data-aos="slide-left" className="triangle-pattern-sand"></div>
          <div data-aos="slide-right" className="triangle-pattern-aqua"></div>
        </div>
      </section>

      <section className="cta-jumbo-section">
        <h5>{aboutUsPageConfigs.JoinUs.caption}</h5>
        <a href={aboutUsPageConfigs.JoinUs.url} className="cta-button" target={aboutUsPageConfigs.JoinUs.target}>
          {aboutUsPageConfigs.JoinUs.button_text}
        </a>
      </section>
    </Layout>
  )
}

export default AboutUsPage;
