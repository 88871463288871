import React from "react";

import GetWebpFormat from "../utils/getWebpFormat";

const ResponsiveImage = (props) => {
  const src = props.src;

  const insertResponsiveParamsAt = 'upload/'
  const indexToInsertResponsiveParams = src.indexOf(insertResponsiveParamsAt) + (insertResponsiveParamsAt.length);

  const breakpoints = [1366, 768, 375];

  const extension = src.substr(src.lastIndexOf('.') + 1);

  const getResponsiveImageSrc = (width) => {
    let responsiveParams = `w_${width},f_auto/`;

    if (extension === 'gif') {
      responsiveParams = `w_${width}/`;
    }

    return src.substr(0, indexToInsertResponsiveParams) + responsiveParams + src.substr(indexToInsertResponsiveParams);
  }

  return (
    <picture>
      {
        breakpoints.map(breakpoint => {
          return (
            <source
              key={breakpoint}
              media={`(min-width: ${breakpoint}px)`}
              srcSet={getResponsiveImageSrc(breakpoint)}
            />
          )
        })
      }

      <img src={GetWebpFormat(src)} alt={props.alt}/>
    </picture>
  )
}

export default ResponsiveImage;
